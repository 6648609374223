import React from "react";
import InternalLink from "../InternalLink";
import { Container, Section, Title } from "../../sdk/Layout";
import { Items, Item } from "./style";
import Icons from "../Icons2/index";

function ActionsCTA({ config }) {
    if (config != null && config.length <= 0) return null;

    return (
        <Section>
            <Container>
                <Items>
                    {config.map((index, i) => (
                        <Item key={`action-cta-${i}`}>
                            <Icons icon={index.icon} />
                            <Title as="h4" size="6">
                                {index.header}
                            </Title>
                            <p>{index.paragraph}</p>
                            {index.link && index.cta ? (
                                <InternalLink {...index.link}>
                                    {index.cta}
                                </InternalLink>
                            ) : null}
                        </Item>
                    ))}
                </Items>
            </Container>
        </Section>
    );
}

export default ActionsCTA;
