import styled from "styled-components";
import { Container, Section, Title } from "../../sdk/Layout";

export const Items = styled.div`
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 540px) {
        flex-direction: column;
    }
`;

export const Item = styled.div`
    flex-grow: 1;
    flex-basis: 33.333%;
    text-align: center;
    padding: 0 10px;
    svg {
        width: 25px;
        height: 25px;
        object-fit: contain;
        margin-bottom: 10px;
        color: ${props => props.theme.primaryDark};
    }
    p {
        margin: 0;
        padding: 0;
        font-size: 15px;
        color: ${props => props.theme.gray};
        letter-spacing: 0;
        text-align: center;
        line-height: 21px;
        margin: auto;
        display: block;
        max-width: 300px;
    }
    a {
        display: inline-block;
        font-size: 16px;
        text-decoration: underline;
        margin-top: 20px;
        color: ${props => props.theme.black};
    }

    @media (max-width: 768px) {
        padding: 0px 5px;
        svg {
            width: 20px;
            height: 20px;
            margin-bottom: 5px;
        }
        & ${Title} {
            font-size: 14px;
            margin-bottom: 10px;
        }
        p {
            max-width: 200px;
            font-size: 12px;
            line-height: 14px;
        }
        a {
            font-size: 14px;
            margin-top: 10px;
        }
    }

    @media (max-width: 540px) {
        & + & {
            margin-top: 20px;
        }
    }
`;
